var config = {};
config.AppKey = '3355B39D-5172-4761-9427-215BD5CBEF98';
config.PersonaAppKey='16B52686-C198-4F54-A434-BA4268A2F44B';
config.MexicoAppKey = '8CCE809C-ABBD-473F-A335-4CF8D8D0F9AF';
config.serviceURL = 'https://ccxapiqa.arifleet.com/api/';//http://localhost:60980/api/'; //'http://mtlwdvintxiis01:92/api/'; //'http://192.168.150.217:82/api/';
config.logServiceURL='http://localhost:60980/api/';//http://mtlwdvintxiis01:92/api/';
config.shopViewRefreshFrequencyInMinutes = 10;
config.sessionExpirationTimeInMinutes=30; /*session expiration time in minutes*/
config.sessionExpirationCheckFrequencyInMinutes = 1;
config.taxName = 'Standard';
config.searchDelay=400;
config.throttledSaveStateInSeconds = 3;
config.recentServicePageSize = 5; 
config.repairHistoryPageSize = 25;
config.supplierSiteLink = "http://intislqa.arifleet.com/SUPPLIERS/default.aspx?referral=APC";
config.enableVMSLogging = false; 
config.reCaptchaKey="6LcEbFkeAAAAACJVyz0pCQ_W9WI7VpYkYKzfE6Q1";
config.AccmanApiUrl = 'https://api.stg.holman.solutions/accmanV2/';
config.accidentAssignmentsViewRefreshFrequencyInMinutes = 10;
config.accmanConcurentImageUpload = 5;
/*
//These are the keys for the different environments for recaptcha
config.reCaptchaKeyLocal='6Lcia1keAAAAAH0seHi8t5JKeHsVo37F6ZdDO0pc';
config.reCaptchaKeyDevelopment='6LcEbFkeAAAAACJVyz0pCQ_W9WI7VpYkYKzfE6Q1';
*/
config.holmanPartnerConnectRedirectUrl="https://hpcqa.holman.com";
config.showHolmanRedirectScreen="disabled";
config.version="185.2.586";

module.exports = config;

